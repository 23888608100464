import React from "react";
import "./newProductCard.scss";

const NewProductCard = (props) => {
  const { productName, productImage, href, greyBg = false } = props;

  return (
    <div className="a-new-product-card">
      <a href={href}>
        <img
          className="a-new-product-card__image"
          src={productImage.file.url}
          alt={`Producto ${productName}`}
        />
      </a>
      <a
        className={`a-new-product-card__name ${
          greyBg ? "a-new-product-card__name--grey-bg" : ""
        }`}
        href={href}
      >
        {productName}
      </a>
      {greyBg &&
        <div className="a-new-product-card__arrow">
          <a
            className="icon-chevron-right"
            href={href}
          />
        </div>
      }
    </div>
  )
}

export default NewProductCard;